// @flow

export type LinkProps = {|
  url: string,
  gtm: {
    action: string,
    category: string,
    label: string,
  },
|}

export const singleChannelProps: LinkProps = {
  url: 'https://shop.opentrons.com/ot-2-robot/',
  gtm: {
    action: 'click',
    category: 'b-pipettes',
    label: 'single-buy-button',
  },
}

export const multiChannelProps: LinkProps = {
  url: 'https://shop.opentrons.com/ot-2-robot/',
  gtm: {
    action: 'click',
    category: 'b-pipettes',
    label: 'multi-buy-button',
  },
}

export const singleWhitepaperProps: LinkProps = {
  url: '/publications/Opentrons-Pipette-White-Paper.pdf',
  gtm: {
    action: 'click',
    category: 'b-pipettes',
    label: 'single-whitepaper-button',
  },
}

export const multiWhitepaperProps: LinkProps = {
  url: '/publications/Opentrons-Pipette-White-Paper.pdf',
  gtm: {
    action: 'click',
    category: 'b-pipettes',
    label: 'multi-whitepaper-button',
  },
}

export const tipsProps: LinkProps = {
  url: 'https://shop.opentrons.com/universal-filter-tips/',
  gtm: {
    action: 'click',
    category: 'b-pipettes',
    label: 'tips-buy-button',
  },
}
