import React from 'react'
import Page from '../components/Page'
import SEO from '../components/seo'
import Pipettes from '../components/Pipettes'

const PipettesPage = () => (
  <Page>
    <SEO
      title="OT-2 Pipettes by Opentrons | New Pipettes for Lab Automation"
      description="New High-Precision Pipettes. Reliable liquid transfers from 1 µL -1000 µL. Opentrons Pipettes are designed for lab automation and optimized for use with the OT-2"
    />
    <Pipettes />
  </Page>
)

export default PipettesPage
