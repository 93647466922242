// @flow

import * as React from 'react'
import {LabeledValue} from '../../ui-components'
import * as styles from './Pipette.module.css'

import PIPETTE_HERO from './assets/pipette_hero.png'

export default function Hero() {
  return (
    <section className={styles.hero_container}>
      <div>
        <h2 className={styles.h2}>New High-Precision Pipettes</h2>
        <h5 className={styles.h5}>
          Reliable liquid transfers from 1 µL -1000 µL.
        </h5>
        <h6 className={styles.h4}>
          Opentrons Pipettes are designed for lab automation and optimized for
          use with the OT-2:{' '}
        </h6>
        <LabeledValue
          label="Accurate"
          value="comparable specs to pipettes 10x as expensive "
        />
        <LabeledValue
          label="Flexible"
          value="swap single- and 8-channel pipettes in newly expanded volume ranges to complete protocols"
        />
      </div>
      <div>
        <img src={PIPETTE_HERO} alt="" />
      </div>
    </section>
  )
}
