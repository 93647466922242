// @flow

import * as React from 'react'
import Hero from './Hero'
import {
  Button,
  SpecTable,
  ContentHalf,
  HeadingGroup,
  LinkOut,
} from '../../ui-components'
import {
  singleChannelProps,
  multiChannelProps,
  singleWhitepaperProps,
  multiWhitepaperProps,
  tipsProps,
} from './pipette-data'
import GetInTouch from '../../components/GetInTouch'

import * as styles from './Pipette.module.css'

import {
  SINGLE_CHANNEL_SPECS,
  MULTI_CHANNEL_SPECS,
} from '../../ui-components/tables/pipette-data'

import SINGLE_CHANNEL from './assets/1-channel.png'
import MULTI_CHANNEL from './assets/8_channel.png'
import HOW_IT_WORKS from './assets/pipette_diagram.gif'
import OPENTRONS_TIPS from './assets/compatible_tips.jpg'

export default function Pipettes() {
  return (
    <>
      <Hero />
      <section>
        <div className={styles.pipette_container}>
          <div className={styles.channel_image}>
            <img
              className={styles.single_channel_image}
              src={SINGLE_CHANNEL}
              alt=""
            />
            <div className={styles.button_container}>
              <Button
                className={styles.button}
                Component="a"
                linkOutProps={singleChannelProps}
              >
                buy now
              </Button>
              <Button
                outline
                className={styles.button}
                Component="a"
                linkOutProps={singleWhitepaperProps}
              >
                Read White Paper
              </Button>
            </div>
          </div>
          <div className={styles.channel_specs}>
            <h3 className={styles.h3}>Single-Channel (GEN2).</h3>
            <div className={styles.single_channel_specs}>
              Additional specs and technical drawings for pipettes GEN1 &amp;
              GEN 2 can be found{' '}
              <LinkOut
                className={styles.gen1_link}
                url="/publications/Opentrons-Pipette-White-Paper.pdf"
                gtm={{
                  action: 'click',
                  category: 'b-pipettes',
                  label: 'gen1-single-whitepaper-button',
                }}
              >
                here
              </LinkOut>
              .
            </div>
            <div className={styles.single_channel_specs}>
              Manual can be found{' '}
              <LinkOut
                className={styles.gen1_link}
                url="https://opentrons-landing-img.s3.amazonaws.com/pipettes/Opentrons-GEN2-Pipette-Single-Channel-Brochure-Digital.pdf"
                gtm={{
                  action: 'click',
                  category: 'b-pipettes',
                  label: 'gen2-single-manual-button',
                }}
              >
                here
              </LinkOut>
              . Calibration block manual can be found{' '}
              <LinkOut
                className={styles.gen1_link}
                url="https://opentrons-landing-img.s3.amazonaws.com/pipettes/Calibration-Block-Manual-Digital.pdf"
                gtm={{
                  action: 'click',
                  category: 'b-pipettes',
                  label: 'gen2-single-calibration-button',
                }}
              >
                here
              </LinkOut>
              .
            </div>
            {/* <h6 className={styles.h6}>available ranges</h6>
            <div className={styles.label_value}>
              <LabeledValue label="P20" value="1µl - 20µl" />
              <LabeledValue label="P300" value="20µl - 300µl" />
              <LabeledValue label="P1000" value="100µl - 1000µl" />
            </div> */}
            <SpecTable specData={SINGLE_CHANNEL_SPECS} />
          </div>
        </div>
        <div className={styles.pipette_container}>
          <div className={styles.channel_image}>
            <img
              className={styles.single_channel_image}
              src={MULTI_CHANNEL}
              alt=""
            />
            <div className={styles.button_container}>
              <Button
                className={styles.button}
                Component="a"
                linkOutProps={multiChannelProps}
              >
                buy now
              </Button>
              <Button
                outline
                className={styles.button}
                Component="a"
                linkOutProps={multiWhitepaperProps}
              >
                Read White Paper
              </Button>
            </div>
          </div>
          <div className={styles.channel_specs}>
            <h3 className={styles.h3}>8-Channel (GEN2)</h3>
            <div className={styles.single_channel_specs}>
              Additional specs and technical drawings for pipettes GEN1 &amp;
              GEN 2 can be found{' '}
              <LinkOut
                className={styles.gen1_link}
                url="/publications/Opentrons-Pipette-White-Paper.pdf"
                gtm={{
                  action: 'click',
                  category: 'b-pipettes',
                  label: 'gen1-single-whitepaper-button',
                }}
              >
                here
              </LinkOut>
              .
            </div>
            <div className={styles.single_channel_specs}>
              Manual can be found{' '}
              <LinkOut
                className={styles.gen1_link}
                url="https://opentrons-landing-img.s3.amazonaws.com/pipettes/Opentrons-GEN2-Pipette-8-Channel-Brochure-Digital.pdf"
                gtm={{
                  action: 'click',
                  category: 'b-pipettes',
                  label: 'gen2-8channel-manual-button',
                }}
              >
                here
              </LinkOut>
              . Calibration block manual can be found{' '}
              <LinkOut
                className={styles.gen1_link}
                url="https://opentrons-landing-img.s3.amazonaws.com/pipettes/Calibration-Block-Manual-Digital.pdf"
                gtm={{
                  action: 'click',
                  category: 'b-pipettes',
                  label: 'gen2-8channel-calibration-button',
                }}
              >
                here
              </LinkOut>
              .
            </div>
            {/* <h6 className={styles.h6}>available ranges</h6>
            <div className={styles.label_value}>
              <LabeledValue label="P10" value="1 - 10µl" />
              <LabeledValue label="P50" value="5 - 50µl" />
              <LabeledValue label="P300" value="30 - 300µl" />
            </div> */}
            <SpecTable specData={MULTI_CHANNEL_SPECS} />
          </div>
        </div>
      </section>
      <section className={styles.gray_bg}>
        <div className={styles.container}>
          <ContentHalf className={styles.how_it_works}>
            <HeadingGroup
              header="How it works"
              subHeader="Our integrated design puts the same trusted mechanisms used in all manual pipettes inside a new robotic package."
            />
          </ContentHalf>
          <ContentHalf>
            <img
              className={styles.how_it_works_image}
              src={HOW_IT_WORKS}
              alt=""
            />
          </ContentHalf>
        </div>
      </section>
      <section>
        <div className={styles.container}>
          <ContentHalf>
            <img src={OPENTRONS_TIPS} alt="" />
          </ContentHalf>
          <ContentHalf className={styles.opentrons_tips_content}>
            <HeadingGroup
              header="Tips built for automation:"
              subHeader="Affordable, automation-optimized tips designed specifically for the OT-2—starting at $5.50/rack."
            />
            <Button
              className={styles.button_tips}
              Component="a"
              linkOutProps={tipsProps}
            >
              buy now
            </Button>
          </ContentHalf>
        </div>
      </section>
      <GetInTouch
        title="Ready for Opentrons in your lab?"
        secondaryTitle="Labs in 40+ countries trust Opentrons to help automate their workflows. We offer 60-day risk-free returns & flexible payment options."
        email
        demo
        chat
        gtmCategory={'cta-3'}
        gtmLabelDemo={'schedule-demo'}
        gtmLabelEmail={'email-us'}
        gtmLabelChat={'chat-with-us'}
      />
    </>
  )
}
