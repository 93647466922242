// extracted by mini-css-extract-plugin
export var h2 = "Pipette-module--h2--LY6RU";
export var h3 = "Pipette-module--h3--pAwsz";
export var h4 = "Pipette-module--h4--1z9tO";
export var h5 = "Pipette-module--h5--divrL";
export var h6 = "Pipette-module--h6--SVTSJ";
export var button = "Pipette-module--button--2FGL4";
export var button_tips = "Pipette-module--button_tips--5AtD1";
export var gray_bg = "Pipette-module--gray_bg--Iv2HX";
export var container = "Pipette-module--container--fmXEG";
export var hero_container = "Pipette-module--hero_container--CnzZ9";
export var how_it_works = "Pipette-module--how_it_works--nutx8";
export var how_it_works_image = "Pipette-module--how_it_works_image--tBJNf";
export var gen1_link = "Pipette-module--gen1_link--dDVlX";
export var label_value = "Pipette-module--label_value--ssh1X";
export var single_channel_image = "Pipette-module--single_channel_image--iDpWT";
export var single_channel_specs = "Pipette-module--single_channel_specs--Qf8c-";
export var opentrons_tips_content = "Pipette-module--opentrons_tips_content--ROxxd";
export var pipette_container = "Pipette-module--pipette_container--pKZU9";
export var button_container = "Pipette-module--button_container--1c3JT";
export var channel_image = "Pipette-module--channel_image--AYPee";
export var channel_specs = "Pipette-module--channel_specs--8I5jV";