// @flow
// types and data specific to the PipetteSpecTable component
// possible candidate for a future shared-data folder?

export type PipetteData = {|
  volume: number,
  randomErrorP: string,
  randomErrorU: string,
  systematicErrorP: string,
  systematicErrorU: string,
|}

export type PipetteSpec = {|
  model: string,
  data: Array<PipetteData>,
|}

export const SINGLE_CHANNEL_SPECS = [
  {
    model: 'p20 GEN2',
    data: [
      {
        volume: 1,
        randomErrorP: '±15%',
        randomErrorU: '0.15µL',
        systematicErrorP: '±5%',
        systematicErrorU: '0.05µL',
      },
      {
        volume: 10,
        randomErrorP: '±2%',
        randomErrorU: '0.2µL',
        systematicErrorP: '±1%',
        systematicErrorU: '0.1µL',
      },
      {
        volume: 20,
        randomErrorP: '±1.5%',
        randomErrorU: '0.3µL',
        systematicErrorP: '±0.8%',
        systematicErrorU: '0.16µL',
      },
    ],
  },
  {
    model: 'p300 GEN2',
    data: [
      {
        volume: 20,
        randomErrorP: '±4%',
        randomErrorU: '0.8µL',
        systematicErrorP: '±2.5%',
        systematicErrorU: '0.5µL',
      },
      {
        volume: 150,
        randomErrorP: '±1%',
        randomErrorU: '1.5µL',
        systematicErrorP: '±0.4%',
        systematicErrorU: '0.6µL',
      },
      {
        volume: 300,
        randomErrorP: '±0.6%',
        randomErrorU: '1.8µL',
        systematicErrorP: '±0.3%',
        systematicErrorU: '0.9µL',
      },
    ],
  },
  {
    model: 'p1000 GEN2',
    data: [
      {
        volume: 100,
        randomErrorP: '±2%',
        randomErrorU: '2.0µL',
        systematicErrorP: '±1%',
        systematicErrorU: '1µL',
      },
      {
        volume: 500,
        randomErrorP: '±1%',
        randomErrorU: '5.0µL',
        systematicErrorP: '±0.2%',
        systematicErrorU: '1µL',
      },
      {
        volume: 1000,
        randomErrorP: '±0.7%',
        randomErrorU: '7.0µL',
        systematicErrorP: '±0.15%',
        systematicErrorU: '1.5µL',
      },
    ],
  },
]

export const MULTI_CHANNEL_SPECS = [
  {
    model: 'P20 GEN2',
    data: [
      {
        volume: 1,
        randomErrorP: '±20%',
        randomErrorU: '0.2µL',
        systematicErrorP: '±10%',
        systematicErrorU: '0.1µL',
      },
      {
        volume: 10,
        randomErrorP: '±3%',
        randomErrorU: '0.3µL',
        systematicErrorP: '±2%',
        systematicErrorU: '0.2µL',
      },
      {
        volume: 20,
        randomErrorP: '± 2.2%',
        randomErrorU: '0.44µL',
        systematicErrorP: '±1.5%',
        systematicErrorU: '0.3µL',
      },
    ],
  },
  {
    model: 'P300 GEN2',
    data: [
      {
        volume: 20,
        randomErrorP: '±10%',
        randomErrorU: '2.0µL',
        systematicErrorP: '±4%',
        systematicErrorU: '0.8µL',
      },
      {
        volume: 150,
        randomErrorP: '±2.5%',
        randomErrorU: '3.75µL',
        systematicErrorP: '±0.8%',
        systematicErrorU: '1.2µL',
      },
      {
        volume: 300,
        randomErrorP: '±1.5%',
        randomErrorU: '4.5µL',
        systematicErrorP: '±0.5%',
        systematicErrorU: '1.5µL',
      },
    ],
  },
]
